@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.hero{
    background: url(../../../public/assets/images/hero.webp) no-repeat center center;
    background-position: top;
    background-size: cover;
    height: 75%;
    width: 100%;
    margin-top: 80px;

    .wrapper{
        height: 100%;
        width: 100%; 
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1;
        background-color: #18235bce;
        padding: 0 15%;
        box-sizing: border-box;
        
        >img {
            height: 60%;
            width: 55%;
            object-fit: cover;
            object-position: top;
            border-radius: 8px;
        };

        .titleCard{
            padding: 30px 40px 40px 40px;
            border-radius: 16px;
            background-color: #ffffff;
            width: 40%;
            z-index: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            box-sizing: border-box;
    
            >img{
                width: 50%;
                border-radius: 8px;
                cursor: pointer;
                margin-bottom: 20px;
            }

            >p:first-of-type{
                margin-bottom: 20px;
            }
    
            >p{
                font-family: 'Inter';
                line-height: 20px;
                font-size: 1rem;
                font-weight: 400;
                margin-bottom: 40px;
                text-align: left;
            }

            >a.button {
                background-color: #5056AF;
                font-weight: bold;
                border: none;
                color: white;
                padding: 15px 25px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 1rem;
                border-radius: 8px;
                cursor: pointer;
                margin-bottom: 20px;

                &:hover{
                    background-color: #4047ac;
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .hero{
        .wrapper{
            padding: 0 8%;
        }
    }
}

@media (max-width: 800px) {
    .hero{
        height: auto;

        .wrapper{
            flex-direction: column;
            justify-content: flex-end;
            padding: 0 5%;

            >img {
                height: 60%;
                width: 70%;
                margin: 15% 0 20px 0;
                object-fit: cover;
                object-position: top;
                border-radius: 8px;
            };

            .titleCard{
                margin: 20px 0 15% 0;
                margin-right: 0;
                padding: 30px 20px 40px 20px;
                width: 70%;
                background-color: #ffffff;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                box-sizing: border-box;

                >img{
                    border-radius: 8px;
                    cursor: pointer;
                    margin-bottom: 20px;
                }
    
                >p:first-of-type{
                    margin-bottom: 20px;
                }
        
                >p{
                    font-family: 'Inter';
                    font-size: 1rem;
                    font-weight: 400;
                    margin-bottom: 40px;
                    text-align: left;
                }
    
                >a.button {
                    background-color: #426295;
                    font-weight: bold;
                    border: none;
                    color: white;
                    padding: 15px 25px;
                    text-align: center;
                    text-decoration: none;
                    display: inline-block;
                    font-size: 1rem;
                    border-radius: 8px;
                    cursor: pointer;
                    margin-bottom: 20px;
    
                    &:hover{
                        background-color: #65799A;
                    }
                }
            }
        }
    }
}

@media (max-width: 520px) {
    .hero{
        .wrapper{
            >img {
                width: 100%;
            };

            .titleCard{
                width: 100%;
            }
        }
    }
}