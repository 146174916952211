.schema{
    width: 100%;
    background-color: #010321;
    padding: 100px 15%;
    box-sizing: border-box;

    >img{
        width: 100%;
        height: 100%;
    }

    .mobile{
        display: none;
    }
}

@media (max-width: 520px) {
    .schema{
        padding: 100px 5%;
        .mobile{
            display: block;
        }
        .desktop{
            display: none;
        }
    }
}
