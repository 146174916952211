@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.crew{
    width: 100%;
    padding: 10% 15%;
    background-color: rgb(1, 3, 33);
    box-sizing: border-box;

    .crewCard{
        position: relative;
        width: 100%;
        padding-right: 40px;
        border-radius: 16px;
        background-color: #ffffff;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10%;
        box-sizing: border-box;

        &.reverse{
            flex-direction: row-reverse;
            padding: 0 0 0 40px;

            .imgWrapper{
                margin-left: 20px;
                margin-right: 0;
                border-radius: 0 8px 8px 0;

                >img{
                    border-radius: 0 8px 8px 0;
                }
            }
        }

        .imgWrapper{
            width: 55%;
            height: 500px;
            border-radius: 8px 0 0 8px;
            margin-right: 20px;
            object-fit: cover;
            object-position: top;
            overflow: hidden;
            box-sizing: border-box;

            >img{
                width: 100%;
                height: 100%;
                border-radius: 8px 0 0 8px;
                object-fit: cover;
                object-position: top;

                &.resize{
                    object-position: 50% 70%;
                }
            }
        }

        .left{
            width: 40%;
            display: flex;
            justify-content: center;

            .text{
                width: 100%;
                max-width: 310px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                color: #18214D;
    
                >span:first-of-type{
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 30px;
                    letter-spacing: -0.55px;
                    margin-bottom: 4px;
                }
    
                >span:nth-of-type(2){
                    margin-bottom: 16px;
                    font-weight: 500;
                }

                p, span{
                    text-align: left;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 26px;
                    letter-spacing: -0.3px;
                }
            }
        }
    }

    .crewCard:last-of-type{
        margin-bottom: 0;
    }
}


@media (max-width: 1200px) {
    .crew{
        padding: 10% 8%;
    }
}

@media (max-width: 520px) {
    .crew{
        padding: 20% 5% 10% 5%;

        .crewCard{
            flex-direction: column;
            padding: 0 0 30px 0;
            margin-bottom: 15%;

            &.reverse{
                flex-direction: column;
                padding: 0 0 30px 0;

                .imgWrapper{
                    margin-left: 0;
                    margin-right: 0;
                    border-radius: 8px 8px 0 0;

                    >img{
                        border-radius: 8px 8px 0 0;
                    }
                }
            }

            .imgWrapper{
                width: 100%;
                height: 300px;
                border-radius: 8px 8px 0 0;
                margin-right: 0;
                overflow: hidden;
                box-sizing: border-box;

                >img{
                    width: 100%;
                    height: 100%;
                    border-radius: 8px 8px 0 0;
                }
            }

            .left{
                width: 100%;
                padding: 30px 30px 0 30px;
                box-sizing: border-box;

                .text{
                    width: 100%;
                    max-width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    color: #18214D;
        
                    >span:first-of-type{
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 22px;
                        line-height: 30px;
                        letter-spacing: -0.55px;
                        margin-bottom: 4px;
                    }
        
                    >span:nth-of-type(2){
                        margin-bottom: 16px;
                        font-weight: 500;
                    }
    
                    p, span{
                        text-align: left;
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 22px;
                        letter-spacing: -0.3px;
                    }
                }
            }
        }
    }
}