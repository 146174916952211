.event{
    margin-top: 80px;
    padding: 8% 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .imgWrapper{
        height: 400px;
        width: 50%;
        background: url(../../../public/assets/prestation/band.webp) no-repeat center center;
        border-radius: 8px;
        background-size: cover;
        background-position: center;
    }

    .textWrapper{
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .title{
            width: 90%;
            font-family: 'Inter';
            font-style: normal;
            font-weight: bold;
            font-size: 2rem;
            line-height: 2.5rem;
            color: #000000;
            margin-bottom: 32px;
        }

        .tagWrapper{
            width: 120%;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            margin-bottom: 32px;

            .line{
                display: flex;
                height: 32px;
                box-sizing: border-box;
                margin-bottom: 16px;

                .tag{
                    width: fit-content;
                    height: fit-content;
                    padding: 8px 16px;
                    box-sizing: border-box;
                    background-color: #46498bd2;
                    border-radius: 8px;
                    border: 2px solid #5056AF;
                    margin-left: 10px;

                    >span{
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        letter-spacing: -0.55px;
                        color: #FFFFFF;
                    }
                }

                .tag:first-of-type{
                    margin-left: 0;
                }
            }

            .line:last-of-type{
                margin-bottom: 0;
            }
        }

        .tagWrapperMobile{
            display: none;
        }

        >span{
            width: 90%;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.5rem;
            color: #000000;
            margin-bottom: 20px;
        }
    }
}

@media (max-width: 1200px) {
    .event{
        padding: 8% 10%;

        .imgWrapper{
            width: 45%;
        }

        .textWrapper{
            width: 50%;
            .tagWrapper{
                width: 100%;
                .line{
                    .tag{
                        padding: 8px;

                        >span{
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .event{
        flex-direction: column;
        padding: 8% 5%;

        .imgWrapper{
            width: 100%;
            height: 300px;
            margin-bottom: 32px;
        }

        .textWrapper{
            width: 100%;

            .title{
                width: 100%;
            }

            .tagWrapper{
                width: 100%;
                .line{
                    .tag{
                        padding: 8px 16px;

                        >span{
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 520px) {
    .event{
        padding: 8% 5%;

        .imgWrapper{
            height: 200px;
        }

        .textWrapper{
            max-width: 100vw;
            overflow-x: scroll;

            .title{
                font-size: 1.5rem;
                line-height: 2rem;
            }

            .tagWrapper:first-of-type{
                display: none;
            }

            .tagWrapperMobile{
                display: flex;
                .line{
                    .tag{
                        padding: 8px 16px;

                        >span{
                            font-size: 14px;
                        }
                    }
                }
            }

            >span{
                font-size: 14px;
                line-height: 1.5rem;
            }
        }
    }
}