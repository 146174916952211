.form{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden;

    iframe{
        width: 100vw;
    }
}

iframe{
    width: 100%;
    height: calc(100% - 40px);
    margin-top: 40px !important;
    overflow: visible;
}