@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

header.header{
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #ebebeb;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    box-sizing: border-box;
    padding: 0 15%;

    img{
        height: 60px;
        cursor: pointer;
    }

    .menu{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .options{
            >a{
                font-size: 1rem;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                color: #000;
                cursor: pointer;
                box-sizing: border-box;
                margin-left: 25px;
                text-decoration: none;
    
                &:hover{
                    color: #65799A;
                }
            }
        }

        >a.button {
            font-family: 'Inter';
            background-color: #5056AF;
            font-weight: bold;
            border: none;
            color: white;
            padding: 10px 20px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 1rem;
            border-radius: 8px;
            cursor: pointer;

            &:hover{
                background-color: #4047ac;
            }
        }
    }

    .menu-icon {
        display: none;
        cursor: pointer;
        margin-left: auto;
        border: none;

        >button{
            font-size: 24px;
            color: #000;
            border: 0;
            background-color: transparent;
        }
    }

    @media (max-width: 1200px) {
        padding: 0 8%;
    }

    @media (max-width: 800px) {
        .menu-icon{
            display: block;
        }

        .menu{
            display: none; 
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 80px;
            left: 0;
            width: 100%;
            background-color: #fff;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
            padding: 20px;
            box-sizing: border-box;

            .options{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                >a{
                    font-size: 16px;
                    font-weight: 500;
                    color: #000;
                    cursor: pointer;
                    box-sizing: border-box;
                    margin: 10px 0;
                    text-decoration: none;
        
                    &:hover{
                        color: #65799A;
                    }
                }
            }

            >a.button {
                margin-top: 10px;
            }
    
            &[data-is-open="true"]{
                display: flex;
            }
        }
    }
}